import React, { ReactElement } from 'react'
import { Controller } from 'react-hook-form'
import { Autocomplete as MUIAutocomplete, TextField, styled } from '@mui/material'

const SelectWrapper = styled('div')(() => ({
  marginTop: '0.8rem',
  marginBottom: '1.5rem',
}))

type Props = {
  label: String
  name: string
  control: any
  options: { label: string; value: string | number }[]
  disabled?: boolean
}
export function Autocomplete({ label, name, control, options, disabled }: Props): ReactElement {
  return (
    <SelectWrapper>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          let selectedOption = value
          if (typeof value !== 'object') {
            selectedOption = options.find((it) => it.value === value)
          }
          return (
            <MUIAutocomplete
              disablePortal
              getOptionLabel={(op) => op.label || ''}
              options={options}
              sx={{ width: '100%' }}
              onChange={(_: any, newValue: any) => {
                onChange(newValue)
              }}
              value={selectedOption}
              renderInput={(params) => <TextField {...params} variant="standard" label={label} />}
              disabled={disabled}
            />
          )
        }}
      />
    </SelectWrapper>
  )
}
